export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID, 
        authority: process.env.REACT_APP_OIDC_URL, 
        
        redirectUri: process.env.REACT_APP_OIDC_REDIRECT_URI,
        },
        cache: {
            cacheLocation: 'sessionStorage', 
            storeAuthStateInCookie: false, 
        }
};

export const protectedResources = {
    apiTodoList: {
        endpoint: process.env.REACT_APP_CRM_SERVICE_BASE_URL,
        scopes: {
            write: [process.env.REACT_APP_OIDC_SCOPE],
        },
    },
};

export const loginRequest = {
    scopes: [...protectedResources.apiTodoList.scopes.write],
};