import axios from 'axios'

export const postOpportunity = (data, token) => {

  const postOpportunityAddress = process.env.REACT_APP_OPPORTUNITIES_URL;
  const gatewayName = process.env.REACT_APP_GATEWAY_NAME;
  const gatewayId = process.env.REACT_APP_GATEWAY_ID;

  const refreshPage = () => {
      window.location.reload(false);
    }
  refreshPage()
  
  axios({
      method:'post',
      url: postOpportunityAddress,
      data: data,
      headers: {
        Authorization: 'Bearer ' + token,
        [gatewayName]: gatewayId
    }
    }).then(response => {
      alert("Opportunity was created!");
      return 1;
    }).catch(error => {
      if (error.response.status === 400) {
        alert("There was a bad request sent to the server (400) please try again")
        refreshPage()
      } else if (error.response.status === 404) {
          alert("The entity requested was not found (404) please try again")
          refreshPage()
      } else if (error.response.status === 500) {
        alert("There was an internal server error (500) please try again")
        refreshPage()
      }  else {
        alert("There was an unexpected error please try again")
        refreshPage()
      }
      return 0;
    })
}

export const postContact = (data, token) => {
  const postContactAddress = process.env.REACT_APP_PARTIES_URL;
  const gatewayName = process.env.REACT_APP_GATEWAY_NAME;
  const gatewayId = process.env.REACT_APP_GATEWAY_ID;

  axios({
      method:'post',
      url: postContactAddress,
      data: data,
      headers: {
          Authorization: 'Bearer ' + token,
          [gatewayName]: gatewayId
      }
  }).then(response => {
      alert("Contact was created!")
  })
  .catch(error => {
    if (error.response.status === 500) {
      
      alert("error: 500, something went wrong!")
    }
  });
}

export const getOpportunities = (token) => {

    const url = process.env.REACT_APP_OPPORTUNITIES_URL;

     return axios.get(url, {
        headers: {
        Authorization: 'Bearer ' + token
        }
    })
}