import React from 'react'
import { Typeahead } from 'react-bootstrap-typeahead';
import NotFoundPage from '@ErrorScreens/NotFoundPage';
import axios from 'axios';
import './OpportunitySpotting.scss'

const OpportunityAssignedOwnerInputs = (props) => {

  const ownerUrl = process.env.REACT_APP_MANAGER_URL;

  const [OwnerOptions, setOwnerOptions] = React.useState([]);

  const [OwnerValue, setAssignedOwnerValue] = React.useState(null);

  const gatewayName = process.env.REACT_APP_GATEWAY_NAME;
  const gatewayId = process.env.REACT_APP_GATEWAY_ID;

  React.useEffect(() => {
    axios.get(ownerUrl, {
      headers: {
        Authorization: 'Bearer ' + props.user.accessToken,
        [gatewayName]: gatewayId
      }
    })
    .then(response => {
      setOwnerOptions(response.data)
    })
    .catch(error => {
      if (error.response.status === 400) {
        alert("400")
      } else if (error.response.status === 404) {
        <NotFoundPage />
      } else if (error.response.status === 500) {
        alert("500")
      } 
    })
  },[props?.user?.accessToken, ownerUrl, gatewayName, gatewayId])

  const handleChange = (input) => {
    setAssignedOwnerValue(input);
    props.setAssignedOwner(input[0]);
  }
  
  if (OwnerOptions) {
    return (
      <>
          <Typeahead
            id="fields-form-input"
            className="typeahead"
            labelKey="username"
            onChange={handleChange}
            options={OwnerOptions}
            placeholder="Please enter the Assigned Manager for this opportunity*"
            selected={OwnerValue}
            />
      </>
    );
  }
}

export default OpportunityAssignedOwnerInputs