import React from 'react'
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from 'axios'
import NotFoundPage from '../ErrorScreens/NotFoundPage';
import './OpportunitySpotting.scss'


const SourceInputs = (props) => {

  const sources = [];

  const [source, setSource] = React.useState([]);

  const [sourceValue, setSourceValue] = React.useState(null);

  const sourceUrl = process.env.REACT_APP_SOURCE_URL;
  const gatewayName = process.env.REACT_APP_GATEWAY_NAME;
  const gatewayId = process.env.REACT_APP_GATEWAY_ID;

  React.useEffect(() => {
    axios.get(sourceUrl, {
      headers: {
        Authorization: 'Bearer ' + props.user.accessToken,
        [gatewayName]: gatewayId
      }
    })
    .then(response => {
      setSource(response.data)
    })
    .catch(error => {
      if (error.response.status === 400) {
        alert("400")
      } else if (error.response.status === 404) {
        <NotFoundPage />
      } else if (error.response.status === 500) {
        alert("500")
      }
    })
  },[props?.user?.accessToken, sourceUrl, gatewayName, gatewayId])
  
  for (let i = 0; i < Object.getOwnPropertyNames(source).length; i++) {
    sources.push(Object.getOwnPropertyNames(source)[i]);
  }

  const handleChange = (input) => {
    setSourceValue(input);
    props.setSources(Object(source)[input]);
  }

  if (source) {
    return (
      <div>
          <Typeahead
            id="fields-form-input"
            labelKey="source"
            onChange={handleChange}
            options={sources}
            placeholder="Please enter the Lead Source for this opportunity*"
            selected={sourceValue}
            />
      </div>
    );
  }
}

export default SourceInputs