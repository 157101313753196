export default class Contact  {
    constructor() {
        this.title = '';
        this.type = '';
        this.firstName = '';
        this.lastName = '';
        this.jobTitle = '';
        this.email = '';
        this.phoneNumber = '';
        this.organisation = {};
    }
}