import React, {useState, useEffect} from 'react'
import Tile from './Tile'
import './Profile.scss'
import axios from 'axios'

// Profile Page
const Profile = (props) => {

  const url = process.env.REACT_APP_MY_OPPORTUNITIES_URL;
  const gatewayName = process.env.REACT_APP_GATEWAY_NAME;
  const gatewayId = process.env.REACT_APP_GATEWAY_ID;
  
  const [opportunity, setOpportunity] = useState([]);
  
  useEffect(() => {
    axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + props.user.accessToken,
        [gatewayName]: gatewayId
      }
    })
      .then(response => {
        if(response.data.length === 0){
          setOpportunity(() => null);
        }else{
          setOpportunity(()=>response.data)
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          alert("There was a 500 error")
        }
      })
}, [props?.user?.accessToken, url, gatewayName, gatewayId])

  return (
    <div className='profile-container'>
      <div className="heading-container">
        <div className="profileHeading">
          <h1>My Opportunities - {props.user.account.name}</h1>
        </div>
      </div>
      <hr className="solid" id="heading-divider" /> 
      <div className="tableBox" title="tile-panel">
        {opportunity !== null ? (
          <Tile opportunity={opportunity} />
        ):(
          <Tile opportunity={null} />
        )}
      </div>
    </div>
  )
}

export default Profile