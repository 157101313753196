import React, { useEffect } from 'react';
import axios from 'axios';
import './OpportunitySpotting.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';

// Client Contact Name and Create New Contact Component
const ClientInput = (props) => {

  const url = process.env.REACT_APP_CLIENTS_URL
  const gatewayName = process.env.REACT_APP_GATEWAY_NAME;
  const gatewayId = process.env.REACT_APP_GATEWAY_ID;

  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    if (searchTerm) {
    if (searchTerm.length < 3) {
      setSearchResults([]);
    } else {
      let isCancelled = false;
      const fetchData = async () => {
        setIsLoading(true);
        try {
          await axios({
            method: 'get',
            url: url + "?query=" + searchTerm,
            headers: {
              Authorization: 'Bearer ' + props.user.accessToken,
              [gatewayName]: gatewayId
            }
          }).then((response) => {
            if (!isCancelled) {
              setSearchResults(response.data);
            } 
          }).catch((error) => {
            console.log('There was an error fetching the data');
          })
        } catch (error) {
          console.log('There was an error with fetching the data');
        } finally {
          setIsLoading(false);
        }
      };

      if (searchTerm.trim() !== '') {
        fetchData();
      } else {
        setSearchResults([]);
      }

      return () => {
        isCancelled = true;
      };
      }
    }
  }, [props?.user?.accessToken, url, searchTerm, gatewayName, gatewayId])
  
  const handleInputChange = (event, value) => {
    setSearchTerm(value);
  }

  const handleSelectOption = (event, value) => {
    if (value) {
      props.setClient(value);
    }
  };

  return (
    <div>
      <Autocomplete
        options={searchResults}
        getOptionLabel={(option) => option ? option.clientName : ''}
        loading={isLoading}
        onInputChange={handleInputChange}
        onChange={handleSelectOption}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Please enter a client*"
            variant="outlined"
          />
        )}
      />
    </div>
  );
}

export default ClientInput