import React, { useEffect } from 'react'
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import ContactModal from '@Modal/ContactModal';
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './OpportunitySpotting.scss'

// Client Contact Name and Create New Contact Component
const ClientContactInput = (props) => {

  const url = process.env.REACT_APP_CONTACTS_URL
  const gatewayName = process.env.REACT_APP_GATEWAY_NAME;
  const gatewayId = process.env.REACT_APP_GATEWAY_ID;

  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  

  useEffect(() => {
    if (searchTerm) {
    if (searchTerm.length < 3) {
      setSearchResults([]);
    } else {
      let isCancelled = false;
      const fetchData = async () => {
        setIsLoading(true);
        try {
          await axios({
            method: 'get',
            url: url + "?query=" + searchTerm,
            headers: {
              Authorization: 'Bearer ' + props.user.accessToken,
              [gatewayName]: gatewayId
            }
          }).then((response) => {
            if (!isCancelled) {
              setSearchResults(response.data);
            } 
          }).catch((error) => {
            console.log('There was an error fetching the data');
          })
        } catch (error) {
          console.log('There was an error with fetching the data');
        } finally {
          setIsLoading(false);
        }
      };

      if (searchTerm.trim() !== '') {
        fetchData();
      } else {
        setSearchResults([]);
      }

      return () => {
        isCancelled = true;
      };
      }
    }
  }, [props?.user?.accessToken, url, searchTerm, gatewayName, gatewayId])
  
  const handleInputChange = (event, value) => {
    setSearchTerm(value);
    setSelectedOptions(null);
  }
  const handleSelectOption = (event, value) => {
    setSelectedOptions([selectedOptions, value]);
    props.setContacts(value);
  }


  return (
    <div>
      <Autocomplete
        multiple
        className="client-contact-input"
        options={searchResults}
        getOptionLabel={(contactOptions) => `${contactOptions.firstName} ${contactOptions.lastName}`}
        loading={isLoading}
        onInputChange={handleInputChange}
        onChange={handleSelectOption}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Please enter a client contact*"
            variant="outlined"
          />
        )}
      />
      <ContactModal user={props.user} />
    </div>
  );
}

export default ClientContactInput