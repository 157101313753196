import { React, useEffect } from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import ProfilePage from './pages/ProfilePage';
import NotFoundPage from './components/ErrorScreens/NotFoundPage';
import './App.scss';
import { MsalProvider, useIsAuthenticated, useMsal} from '@azure/msal-react';
import { InteractionRequiredAuthError} from '@azure/msal-browser';
import { loginRequest } from "./authConfig.js";




function App({ msalInstance }) {

  return (
      <MsalProvider instance={ msalInstance }>
        <BrowserRouter>
          <Pages />
        </BrowserRouter>
    </MsalProvider>
  )
}

const Pages = () => {
  
  const { instance, inProgress} = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(()=>{
    if(!isAuthenticated && !inProgress){
  
      instance.ssoSilent(loginRequest)
      .then((response)=>{
        instance.setActiveAccount(response.account);
      })
      .catch((error)=>{
        console.log('error: caught in app.js:\n' + error);
        if(error instanceof InteractionRequiredAuthError){
          instance.loginRedirect(loginRequest);
        }
      });
    }
  }, [instance, inProgress, isAuthenticated])

  return(
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/profile" element={<ProfilePage />}/>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
