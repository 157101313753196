import React from 'react';
import {Field, Formik, Form} from 'formik';
import {TextField, Button, Modal, Box} from '@mui/material';
import { MdClose } from 'react-icons/md';
import CompanyModalInput from './CompanyModalInput';
import Contact from '@Models/contact.js';
import './ContactModal.scss'
import { postContact } from '@Services/CrmService';

const ContactModal = (props) => {

    const contact = new Contact();

    const [open, setOpen] = React.useState(false);

    let contactProps = {
        variant: "outlined",
        className: "modal-form-input",
        margin: "dense",
        fullWidth: true,
        size: "small",
        sx: {
            input: {
                color: "black"
            }
        },
        required: true,
        as: TextField
    }
 
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const setCompany = (value) => {
        contact.organisation = value;
    }

    const refreshPage = () => {
        window.location.reload(false);
      }

    const handleInvalidInputs = (data) => {
        if (contact.organisation == null) {
            alert("Please select a company for the contact")
            refreshPage()
        }
    }
    
    const containsLetter = (str) => {
        return /[a-zA-Z]/.test(str);
    }

    const containsNumber = (str) => {
        return /\d/.test(str)
    }

    const validatePhoneNumber = (value) => {
        let error;

        if (value.length < 10) {
            error = "Invalid phone number length"
        } else if (containsLetter(value)) {
            error = "Phone Number cannot contain characters"
        }

        return error;
    }

    const validateJobType = (value) => {
        let error;

        if (value.length < 5) {
            error = "Insufficent input"
        } else if (containsNumber(value)) {
            error = "Job Type cannot contain numbers"
        }
        return error;
    }

    return (
    <>
        <div>
            <Formik 
                validateOnChange={true}
                initialValues={contact}
                onSubmit={(data, {setSubmitting, resetForm}) => {
                    handleInvalidInputs(data);
                    setSubmitting(true);
                    data.type = 'person';
                    data.organisation = contact.organisation
                    postContact(data, props.user.accessToken)
                    setSubmitting(false)
                    resetForm(
                        contact.firstName = '',
                        contact.title = '',
                        contact.type = '',
                        contact.lastName = '',
                        contact.jobTitle = '',
                        contact.email = '',
                        contact.phoneNumber = '',
                        contact.organisation = {}
                    )
                    handleClose();
                }}>
                {({values, errors, touched}) => (
                    <>
                        <Button onClick={handleOpen} id="new-contact" title="modal-button">+ create new contact</Button>
                        <Modal open={open} onClose={handleClose} className="custom-modal" title="contact-modal">
                            <Box className="custom-modal-content">
                                <div className="exit-container">
                                    <Button id="close-button-container" onClick={handleClose}>
                                        <MdClose />
                                    </Button>
                                </div>
                                <div className='custom-modal-header'>
                                    <h3 id="modal-title">New Contact</h3>
                                </div>
                                <Form className="custom-modal-body" autoComplete='off'>
                                    <h3>Title</h3>
                                    <Field as="select" name="title" className="custom-modal-form-dropdown" label="None">
                                        <option className="options" value="miss">Miss</option>
                                        <option className="options" value="mr">Mr</option>
                                        <option className="options" value="mrs">Mrs</option>
                                        <option className="options" value="other">Other</option>
                                    </Field>
                                    <div className="name-container">
                                        <div className="first-name-container">
                                            <h3>First Name</h3>
                                            <Field name="firstName" {...contactProps} />
                                        </div>
                                        <div className="last-name-container">
                                            <h3>Last Name</h3>
                                            <Field name="lastName" {...contactProps} />
                                        </div>
                                    </div>
                                    <h3>Job Title</h3>
                                    <Field name="jobTitle" validate={validateJobType} {...contactProps} />
                                    {errors.jobTitle && touched.jobTitle && <div className="error-message">{errors.jobTitle}</div>}
                                    <h3>Email</h3>
                                    <Field type="email" name="email" {...contactProps} />
                                    <h3>Phone</h3>
                                    <Field name="phoneNumber" validate={validatePhoneNumber} {...contactProps} />
                                    {errors.phoneNumber && touched.phoneNumber && <div className="error-message">{errors.phoneNumber}</div>}
                                    <h3>Company/Client</h3>
                                    <CompanyModalInput setCompany={setCompany} user={props.user}/>
                                    <div className='custom-modal-footer'>
                                        <button type="submit" id="submit-button">create</button>
                                    </div>
                                </Form>
                            </Box>
                        </Modal>
                    </>
                )}
            </Formik>
        </div>
    </>
  )
}

export default ContactModal

