import { React, useState, useEffect } from 'react'
import Profile from '@Profile/Profile.js'
import Navbar from '@Navbar/Navbar.js'
import Sidebar from '@Navbar/Sidebar.js'
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType} from '@azure/msal-browser';
import { loginRequest } from '../authConfig';

const ProfilePage = () => {

  const { instance } = useMsal();

  const currentAccount = instance.getActiveAccount();

  const {result, error} = useMsalAuthentication(InteractionType.Redirect, loginRequest);

  useEffect(() => {
    if(!!error){
    console.log(error);
    return;
    }
}, [result, error]);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  if(result){
    return(
      <>  
        <Sidebar isOpen={isOpen} toggle={toggle}  username={currentAccount !== null ? currentAccount.username : "No account"}/>
        <Navbar toggle={toggle}/>
        <Profile username={currentAccount.username} user={result}/>
    </>
    )
  }
}

export default ProfilePage