import React from 'react'
import {Link} from 'react-router-dom'
import './ErrorPages.scss'

const NotFoundPage = () => {
  return (
    <div className="error-container">
      <div className='error-heading-container'>
        <h1>404 Page Not Found</h1>
      </div>
      <div className="error-link-container">
        <Link to="/">
          <h2>Go back to Opportunity app</h2>
        </Link>
      </div>
    </div>
  )
}

export default NotFoundPage