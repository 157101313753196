import React from 'react';
import logo from '@Images/white-logo.png'
import MenuIcon from '@mui/icons-material/Menu';
import './NavbarElements.scss';
import { Link } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';


const Navbar = ({toggle}) => {
    
    const { instance } = useMsal();

    const currentAccount = instance.getActiveAccount();

    const isAuthenticated = useIsAuthenticated();
    
    return (
        <>
            <div className='custom-navbar'>
                    <div className='nav-heading-container'>
                        <div className='nav-logo-container'>
                            <img src={logo} id='logo' alt='logo'/>
                        </div>
                        <Link className="link" to="/">
                            <h1 id='nav-heading'>Opportunity Spotting</h1>
                        </Link>
                    </div>
                    <div className='nav-profile-container'>
                        <div className='button-container'>
                            <div className="nav-button-container">
                            </div>
                            <div className="nav-button-container">
                                <div className='nav-button'>
                                    <Link to="/">
                                    <button className="navigation-button">Create New Opportunity</button>
                                    </Link>
                                </div>
                                <div className='nav-button'>
                                    <Link to="/profile">
                                    <button className="navigation-button">My Opportunities</button>
                                    </Link>
                                </div>
                            </div>
                            <div className='nav-button-container'>
                                <div className='auth-button-container'>
                                    <div className='nav-button'>
                                        {isAuthenticated ? 
                                        <p className="current-user" >{currentAccount.username}</p> :
                                        <p className="current-user" >No User</p> }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='menu-container' onClick={toggle} >
                            <MenuIcon id="hamburger"/>
                        </div>
                    </div>
                    
            </div>
        </>
    )
}

export default Navbar