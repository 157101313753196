//package au.com.integral.integration.canonical.model;

export default class Opportunity  {
    constructor() {
        this.id = null;
        this.opportunitySummary = '';
        this.opportunityDetails = '';
        this.client = null;
        this.engagement = null;
        this.source = "";
        this.owner = {};
        this.department = null;
        this.managingPrincipal = {
			username: ""};
        this.spottedBy = {
			firstName: null,
			lastName: null,
			username: ""};
        this.spottingBonusPaid = false;
        this.milestone = "";
        this.probability = null;
        this.expectedClosedDate = null;
        this.createdDate = null;
        this.closedDate = null;
        this.expectedValue = null;
        this.probabilityValue = null;
        this.tags = [];
        this.contacts = [{}];
    }
}