import React from 'react';
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid';
import { Stack } from 'react-bootstrap';


// Tile element that holds all of the opportunities
const Tile = (props) => {

    const columns = [
        { 
            field: 'opportunitySummary',
            headerName: 'Summary',
            width: 350
        },
        {
            field: 'opportunityDescription',
            headerName: 'Description',
            width: 350
        },
        { 
            field: 'clientName',
            headerName: 'Client',
            width: 350
        },
        { 
            field: 'engagement',
            headerName: 'Engagement',
            width: 350
        },
        { 
            field: 'assignedManager',
            headerName: 'Assigned Managing Principal',
            width: 350
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 200
        },
        {
            field: 'paidStatus',
            headerName: 'Paid',
            width: 200
        }
    ]

    const createRows = (opportunities) => {
        const rows = []
        for (let i = 0; i < opportunities.length; i++) {
            let curClientName = (opportunities[i].client == null) ? "" : opportunities[i].client.clientName
            let curEngagement = (opportunities[i].client == null) ? "" : opportunities[i].client.clientName
            let curAssignedManager = (opportunities[i].owner == null) ? "" : opportunities[i].owner.username

            rows.push({
                id: i,
                opportunitySummary: opportunities[i].opportunitySummary, 
                opportunityDescription: opportunities[i].opportunityDetails,
                clientName: curClientName,
                engagement: curEngagement,
                assignedManager: curAssignedManager,
                status: opportunities[i].milestone,
                paidStatus: opportunities[i].spottingBonusPaid
            })
        }
        return rows;
    }

    const CustomNoRowsOverlay = () => {
        return (
            <Stack style={{ height:"100%", alignItems:"center", justifyContent:"center" }}>
                No Opportunities Found Under Your Account!
            </Stack>
        );
    };

    return (
        <Box>
            <div style={{height: 600, width: '100%'}}>
            {props.opportunity !== null ? (
                    <DataGrid
                    rows={createRows(props.opportunity)}
                    columns={columns}
                    pageSize={9}
                    rowsPerPageOptions={[9]}
                    checkboxSelection
                    disableMultipleSelection={true}/>
                ) : (
                    <DataGrid
                    rows={[]}
                    columns={columns}
                    checkboxSelection
                    disableMultipleSelection={true}
                    localeText={{
                        noRowsLabel: <CustomNoRowsOverlay />
                      }}
                    components={{
                    NoRowsOverlay: CustomNoRowsOverlay
                    }}
                    />
                )}
            </div>
        </Box>
    )
}

export default Tile

