import React from 'react';
import OpportunityInputs from './OpportunityInputs';
import './OpportunitySpotting.scss';

const Form = (props) => {
  return (
    <>
      <div className='form-container'>
        <OpportunityInputs data-testid="opportunity-inputs-form" username={props.username} user={props.user}/>
      </div>
    </>
  )
}

export default Form