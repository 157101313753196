import { React, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import {TextField, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Opportunity from '@Models/opportunity.js';
import ClientContactInput from './ClientContactInput';
import ClientInput from './ClientInput'
import OpportunityAssignedOwnerInputs from './OpportunityAssignedOwnerInputs';
import SourceInputs from './SourceInputs';
import './OpportunitySpotting.scss';
import { postOpportunity } from '@Services/CrmService';

const OpportunityInputs = (props) => {

  const [success, setSuccess] = useState(null);

  const opportunity = new Opportunity();

  let opportunityProps = {
    type: "input",
    as: TextField,
    fullWidth: true,
    margin: "normal",
    size: "medium",
    variant: "outlined",
  }

  const setContacts = (values) => {
    opportunity.contacts = values;
  }

  const setClients = (value) => {
    if (value) {
      opportunity.client = value;
    } else {
      opportunity.client = ''
    }
  }

  const setAssignedOwner = (value) => {
    opportunity.owner = value;
  }

  const setSources = (value) => {
    opportunity.source = value
  }

  const refreshPage = () => {
    window.location.reload(false);
  }

  const handleEmptyFields = () => {
    if (opportunity.contacts !== null && opportunity.contacts.length < 1) {
      alert("Please select a client contact for the opportunity")
      refreshPage()
    } else if (opportunity.client !== null && opportunity.client.length < 1) {
      alert("Please provide a client that links to the opportunity")
      refreshPage()
    } else if (opportunity.owner === null) {
      alert("Please select a Owner for this opportunity")
      refreshPage()
    }
  }

  const handleSubmit = (data, {setSubmitting}) => {
    handleEmptyFields()
    setSubmitting(true);
   
    data.contacts = opportunity.contacts;
    data.client = opportunity.client;
    data.source = opportunity.source;
    data.owner = opportunity.owner;
    data.milestone = "PROSPECT";
    var fullName = (props.user.account.name).split(' '),
        givenName = fullName[0],
        otherNames = fullName[fullName.length - 1];
    data.spottedBy = {
      firstName: givenName,
			lastName: otherNames,
      username: props.username}
    data.paid = false;
    setSuccess(postOpportunity(data, props.user.accessToken));

    setSubmitting(false)
    if (success === 1) {
      <Link to="/profile" />
    }
  }

  const validateDetails = (value) => {
    let error;

    if (value.length < 30) {
        error = "Please provide a detailed description"
    } 
    return error;
  }
 
  return (
      <>
        <div>
          <Formik 
          validateOnChange={true}
          initialValues={opportunity}
          enableReinitialize={true}
          onSubmit={handleSubmit}>
            {({values, isSubmitting, errors, touched}) => (
              <Form autoComplete='off'>
                  <div className='form-header'>
                    <h1>New Opportunity</h1>
                  </div>
                  <hr id="heading-divider" /> 
                  <div className="form-content">
                    <div className='section-titles'>
                      <h1>Opportunity Information</h1>
                    </div>
                    <hr className="sub-heading-divider" /> 
                    <div className="question-info">
                      <h3>Opportunity Summary</h3>
                      <span className='astrix'>*</span>
                    </div>
                      <Field label="Please enter a brief summary of the opportunity" name="opportunitySummary" required={true} onKeyPress={e => { e.which === 13 && e.preventDefault() }} {...opportunityProps}/>
                    <div>
                      <div className="question-info">
                        <h3>Opportunity Details</h3>
                        <span className='astrix'>*</span>
                      </div>
                        <Field label="Please describe the nature of the opportunity" name="opportunityDetails" validate={validateDetails} required={true} multiline={true} rows={3} onKeyPress={e => { e.which === 13 && e.preventDefault() }} {...opportunityProps}/>
                        {errors.opportunityDetails && touched.opportunityDetails && <div className="error-message">{errors.opportunityDetails}</div>}
                    </div>
                    <div>
                      <h1>Client Information</h1>
                      <hr className="sub-heading-divider" /> 
                      <div className="question-info">
                        <h3>Client Name</h3>
                        <span className='astrix'>*</span>
                      </div>
                      <ClientInput setClient={setClients} user={props.user}/>
                    </div>
                    <div className="question-info">
                      <h3 id="client-contact">Client Contact Name(s)</h3>
                      <span className='astrix'>*</span>
                    </div>
                    <div>
                    <ClientContactInput setContacts={setContacts} user={props.user}/>
                    </div>
                    <div>
                      <h1>Integral Information</h1>
                    </div>
                    <hr className="sub-heading-divider" /> 
                    <div>
                      <div className="question-info">
                        <h3>Opportunity Assigned Owner</h3>
                        <span className='astrix'>*</span>
                      </div>
                      <div title='manager-input'>
                        <OpportunityAssignedOwnerInputs setAssignedOwner={setAssignedOwner} user={props.user}/>
                       </div>
                      </div>
                    <div className="source-container">
                      <h3>Source</h3>
                      <div title="source-inputs">
                        <SourceInputs setSources={setSources} user={props.user}/>
                      </div>
                    </div>
                    <div className="create-button-container">
                      <Button disabled={isSubmitting} type="submit" id="create-button" title="create-button">+ create</Button>
                    </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </>
  )
}
export default OpportunityInputs