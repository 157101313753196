import React from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication, EventType} from "@azure/msal-browser";
import { msalConfig } from "./authConfig.js";
import App from './App';

const pca = new PublicClientApplication(msalConfig);

pca.addEventCallback((event) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
    ) {
        pca.setActiveAccount(event.payload.account);
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render (
    <App msalInstance={pca} />
);

