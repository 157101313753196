import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import './Sidebar.scss'

const Sidebar = ({isOpen, toggle, username}) => {
    if (isOpen) {

        return (
        <div id="side-bar-container" onClick={toggle} >
            <div className="icon" onClick={toggle}>
                <CloseIcon id="close" />
            </div>
            <div className="side-bar-wrapper">
                <div className="side-bar-menu">
                    <Link to="/" onClick={toggle} className="side-bar-link">Create Opportunity</Link>
                    <Link to="/profile" onClick={toggle} className="side-bar-link">My Opportunities</Link>
                    <p>{username}</p>
                </div>
            </div>
        </div>
        )
    }
  }

export default Sidebar