import { React, useState, useEffect} from 'react';
import Navbar from '@Navbar/Navbar.js';
import Form from '@OpportunitySpotting/OpportunitySpotting.js'
import Sidebar from '@Navbar/Sidebar.js';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType} from '@azure/msal-browser';
import { loginRequest } from '../authConfig';

const Home = (props) => {

    const { instance } = useMsal();

    const currentAccount = instance.getActiveAccount();

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }    

    const {result, error} = useMsalAuthentication(InteractionType.Redirect, loginRequest);

    useEffect(() => {

        if(!!error){
        console.log(error);
        return;
        }

    }, [result, error]);

    return (
        <>
            <AuthenticatedTemplate>
                <Sidebar isOpen={isOpen} toggle={toggle} username={currentAccount !== null ? currentAccount.username : "No account"}/>
                <Navbar toggle={toggle}/>
                {result ? 
                <Form username={currentAccount !== null ? currentAccount.username : "No account"} user={result !== null ? result : "No User"} />
                :
                <h5>
                    Please Wait!.... Retrieving Account.
                </h5>}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Sidebar isOpen={isOpen} toggle={toggle} />
                <Navbar toggle={toggle}/>
                <h5>
                    Please Wait!.... Attempting to signin.
                </h5>
            </UnauthenticatedTemplate>
        </>
    )
}

export default Home;